import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';

import ProtectedLayout from '@/component-library/layouts/ProtectedLayout/ProtectedLayout';
import ChangePhoneNumberPage from '@/modules/Auth/pages/ChangePhoneNumberPage/ChangePhoneNumberPage';
import CreatePasswordPage from '@/modules/Auth/pages/CreatePasswordPage/CreatePasswordPage';
import ForgotPasswordPage from '@/modules/Auth/pages/ForgotPasswordPage/ForgotPasswordPage';
import ResetPasswordPage from '@/modules/Auth/pages/ResetPasswordPage/ResetPasswordPage';
import SignInPage from '@/modules/Auth/pages/SignInPage/SignInPage';
import SignUpPage from '@/modules/Auth/pages/SignUpPage/SignUpPage';
import VerifyEmailPage from '@/modules/Auth/pages/VerifyEmailPage/VerifyEmailPage';
import ActivityLogs from '@/modules/Dashboard/ActivityLogs/ActivityLogs';
import Audience from '@/modules/Dashboard/Audience/Audience';
import CreateMember from '@/modules/Dashboard/Audience/CreateMember/CreateMember';
import ImportMembers from '@/modules/Dashboard/Audience/ImportMembers/ImportMembers';
import MemberProfile from '@/modules/Dashboard/Audience/MemberProfile/MemberProfile';
import CampaignOverview from '@/modules/Dashboard/Campaigns/CampaignOverview/CampaignOverview';
import Campaigns from '@/modules/Dashboard/Campaigns/Campaigns';
import CreateCampaign from '@/modules/Dashboard/Campaigns/CreateCampaign/CreateCampaign';
import DeleteCampaign from '@/modules/Dashboard/Campaigns/DeleteCampaign/DeleteCampaign';
import Companies from '@/modules/Dashboard/Companies/Companies';
import DashboardPage from '@/modules/Dashboard/DashboardPage/DashboardPage';
import Delivery from '@/modules/Dashboard/Delivery/Delivery';
import NotificationOverviewPage from '@/modules/Dashboard/Delivery/OverviewPage/NotificationOverviewPage';
import NewEmailPage from '@/modules/Dashboard/Messages/Emails/NewEmailPage/NewEmailPage';
import CreatePushNotification from '@/modules/Dashboard/Messages/PushNotifications/CreatePushNotifications';
import EnableAndroidPage from '@/modules/Dashboard/Messages/PushNotifications/EnableAndroidPage';
import EnableIosPage from '@/modules/Dashboard/Messages/PushNotifications/EnableIosPage';
import PushNotifications from '@/modules/Dashboard/Messages/PushNotifications/PushNotifications';
import NewSMSPage from '@/modules/Dashboard/Messages/SMS/NewSMSPage/NewSMSPage';
import CreateSegment from '@/modules/Dashboard/Segments/CreateSegment/CreateSegment';
import SegmentOverview from '@/modules/Dashboard/Segments/SegmentOverview/SegmentOverview';
import Segments from '@/modules/Dashboard/Segments/Segments';
import CompanyAccount from '@/modules/Dashboard/Settings/Account/CompanyAccount';
import PersonalAccount from '@/modules/Dashboard/Settings/Account/PersonalAccount/PersonalAccount';
import APICredentials from '@/modules/Dashboard/Settings/APICredentials/APICredentials';
import DeleteAPICredentials from '@/modules/Dashboard/Settings/APICredentials/Delete/DeleteAPICredentials';
import CreateTeamMember from '@/modules/Dashboard/Settings/CreateTeamMember/CreateTeamMember';
import CreateDomain from '@/modules/Dashboard/Settings/Domain/CreateDomain';
import DomainOverview from '@/modules/Dashboard/Settings/Domain/DomainOverview';
import Domains from '@/modules/Dashboard/Settings/Domain/Domains';
import Settings from '@/modules/Dashboard/Settings/Settings';
import TeamMembers from '@/modules/Dashboard/Settings/TeamMembers/TeamMembers';
import CreateTemplate from '@/modules/Dashboard/Templates/CreateTemplate/CreateTemplate';
import EditTemplate from '@/modules/Dashboard/Templates/EditTemplate/EditTemplate';
import Templates from '@/modules/Dashboard/Templates/Templates';
import WorkspaceDetails from '@/modules/Dashboard/WorkspaceDetails/WorkspaceDetails';
import CreateWorkspace from '@/modules/Dashboard/Workspaces/CreateWorkspace/CreateWorkspace';
import DeleteWorkspace from '@/modules/Dashboard/Workspaces/DeleteWorkspace/DeleteWorkspace';
import EditWorkspace from '@/modules/Dashboard/Workspaces/EditWorkspace/EditWorkspace';
import Workspaces from '@/modules/Dashboard/Workspaces/Workspaces';
import PageNotFound from '@/modules/ErrorPages/PageNotFound/PageNotFound';
import { ContactPage } from '@/modules/LandingPages/ContactPage/ContactPage';
import HomePage from '@/modules/LandingPages/HomePage/HomePage';
import PublicRoute from '@/utils/routes/PublicRoute';
import { AppRoutes } from '@/utils/routes/router';
import { SingleSends } from '@/utils/routes/Routes';

import '@/utils/interceptors/checkTokenInteceptor';

function App() {
  return (
    <div
      className="bg-cover bg-center bg-no-repeat h-screen w-full fixed overflow-auto"
      style={{ backgroundImage: `url('/images/signInBgFull.jpg')` }}
    >
      <div className="overflow-auto">
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <PublicRoute>
                  <HomePage />
                </PublicRoute>
              }
            />
            <Route
              path={AppRoutes.signIn}
              element={
                <PublicRoute>
                  <SignInPage />
                </PublicRoute>
              }
            />
            <Route
              path={AppRoutes.contactUs}
              element={
                <PublicRoute>
                  <ContactPage />
                </PublicRoute>
              }
            />
            <Route
              path={AppRoutes.signUp}
              element={
                <PublicRoute>
                  <SignUpPage />
                </PublicRoute>
              }
            />
            <Route
              path={AppRoutes.verifyEmail}
              element={
                <PublicRoute>
                  <VerifyEmailPage />
                </PublicRoute>
              }
            />
            <Route
              path={AppRoutes.forgotPassword}
              element={
                <PublicRoute>
                  <ForgotPasswordPage />
                </PublicRoute>
              }
            />
            <Route
              path={AppRoutes.createNewPassword}
              element={
                <PublicRoute>
                  <CreatePasswordPage />
                </PublicRoute>
              }
            />
            <Route
              path={AppRoutes.resetPassword}
              element={
                <PublicRoute>
                  <ResetPasswordPage />
                </PublicRoute>
              }
            />
            <Route
              path={AppRoutes.changePhoneNumber}
              element={
                <PublicRoute>
                  <ChangePhoneNumberPage />
                </PublicRoute>
              }
            />

            <Route
              path={AppRoutes.dashboard}
              element={
                <ProtectedLayout>
                  <DashboardPage />
                </ProtectedLayout>
              }
            />
            {/* <Route
              path={AppRoutes.singleSends}
              element={
                <ProtectedLayout>
                  <SingleSends />
                </ProtectedLayout>
              }
            /> */}
            <Route path={AppRoutes.buildMessage} element={<Navigate to={AppRoutes.delivery} />} />

            <Route
              path={AppRoutes.newEmail}
              element={
                <ProtectedLayout>
                  <NewEmailPage />
                </ProtectedLayout>
              }
            />
            <Route
              path={AppRoutes.newSMS}
              element={
                <ProtectedLayout>
                  <NewSMSPage />
                </ProtectedLayout>
              }
            />
            <Route
              path={AppRoutes.campaigns}
              element={
                <ProtectedLayout>
                  <Campaigns />
                </ProtectedLayout>
              }
            />
            <Route
              path={AppRoutes.campaignOverview}
              element={
                <ProtectedLayout>
                  <CampaignOverview />
                </ProtectedLayout>
              }
            />
            <Route
              path={AppRoutes.createCampaign}
              element={
                <ProtectedLayout>
                  <CreateCampaign />
                </ProtectedLayout>
              }
            />
            <Route
              path={AppRoutes.deleteCampaign}
              element={
                <ProtectedLayout>
                  <DeleteCampaign />
                </ProtectedLayout>
              }
            />
            <Route
              path={AppRoutes.delivery}
              element={
                <ProtectedLayout>
                  <Delivery />
                </ProtectedLayout>
              }
            />
            <Route
              path={AppRoutes.members}
              element={
                <ProtectedLayout>
                  <Audience />
                </ProtectedLayout>
              }
            />
            <Route
              path={AppRoutes.companies}
              element={
                <ProtectedLayout>
                  <Companies />
                </ProtectedLayout>
              }
            />
            <Route
              path={AppRoutes.segments}
              element={
                <ProtectedLayout>
                  <Segments />
                </ProtectedLayout>
              }
            />
            <Route
              path={AppRoutes.segmentOverview}
              element={
                <ProtectedLayout>
                  <SegmentOverview />
                </ProtectedLayout>
              }
            />
            <Route
              path={AppRoutes.createSegment}
              element={
                <ProtectedLayout>
                  <CreateSegment />
                </ProtectedLayout>
              }
            />
            <Route
              path={AppRoutes.activityLogs}
              element={
                <ProtectedLayout>
                  <ActivityLogs />
                </ProtectedLayout>
              }
            />
            <Route
              path={AppRoutes.settings}
              element={
                <ProtectedLayout>
                  <Settings />
                </ProtectedLayout>
              }
            />
            <Route
              path={AppRoutes.teamMembers}
              element={
                <ProtectedLayout>
                  <TeamMembers />
                </ProtectedLayout>
              }
            />
            <Route
              path={AppRoutes.account}
              element={
                <ProtectedLayout>
                  <CompanyAccount />
                </ProtectedLayout>
              }
            />
            <Route
              path={AppRoutes.personalAccount}
              element={
                <ProtectedLayout>
                  <PersonalAccount />
                </ProtectedLayout>
              }
            />
            <Route
              path={AppRoutes.createTeamMember}
              element={
                <ProtectedLayout>
                  <CreateTeamMember />
                </ProtectedLayout>
              }
            />
            <Route
              path={AppRoutes.workspaces}
              element={
                <ProtectedLayout>
                  <Workspaces />
                </ProtectedLayout>
              }
            />
            <Route
              path={AppRoutes.workspaceDetails}
              element={
                <ProtectedLayout>
                  <WorkspaceDetails />
                </ProtectedLayout>
              }
            />
            <Route
              path={AppRoutes.createWorkspace}
              element={
                <ProtectedLayout>
                  <CreateWorkspace />
                </ProtectedLayout>
              }
            />
            <Route
              path={AppRoutes.deleteWorkspace}
              element={
                <ProtectedLayout>
                  <DeleteWorkspace />
                </ProtectedLayout>
              }
            />
            <Route
              path={AppRoutes.editWorkspace}
              element={
                <ProtectedLayout>
                  <EditWorkspace />
                </ProtectedLayout>
              }
            />
            <Route
              path={AppRoutes.createMember}
              element={
                <ProtectedLayout>
                  <CreateMember />
                </ProtectedLayout>
              }
            />
            <Route
              path={AppRoutes.importMembers}
              element={
                <ProtectedLayout>
                  <ImportMembers />
                </ProtectedLayout>
              }
            />
            <Route
              path={AppRoutes.memberProfile}
              element={
                <ProtectedLayout>
                  <MemberProfile />
                </ProtectedLayout>
              }
            />
            <Route
              path={AppRoutes.templates}
              element={
                <ProtectedLayout>
                  <Templates />
                </ProtectedLayout>
              }
            />
            <Route
              path={AppRoutes.templatesEdit}
              element={
                <ProtectedLayout>
                  <EditTemplate />
                </ProtectedLayout>
              }
            />
            <Route
              path={AppRoutes.createTemplate}
              element={
                <ProtectedLayout>
                  <CreateTemplate />
                </ProtectedLayout>
              }
            />
            <Route
              path={AppRoutes.pushNotifications}
              element={
                <ProtectedLayout>
                  <PushNotifications />
                </ProtectedLayout>
              }
            />
            <Route
              path={AppRoutes.pushNotificationsEnableIos}
              element={
                <ProtectedLayout>
                  <EnableIosPage />
                </ProtectedLayout>
              }
            />
            <Route
              path={AppRoutes.pushNotificationsEnableAndroid}
              element={
                <ProtectedLayout>
                  <EnableAndroidPage />
                </ProtectedLayout>
              }
            />
            <Route
              path={AppRoutes.newPush}
              element={
                <ProtectedLayout>
                  <CreatePushNotification />
                </ProtectedLayout>
              }
            />
            <Route
              path={AppRoutes.apiCredentials}
              element={
                <ProtectedLayout>
                  <APICredentials />
                </ProtectedLayout>
              }
            />
            <Route
              path={AppRoutes.deleteAPICredentials}
              element={
                <ProtectedLayout>
                  <DeleteAPICredentials />
                </ProtectedLayout>
              }
            />
            <Route
              path={AppRoutes.deliveryOverview}
              element={
                <ProtectedLayout>
                  <NotificationOverviewPage />
                </ProtectedLayout>
              }
            />
            <Route
              path={AppRoutes.domains}
              element={
                <ProtectedLayout>
                  <Domains />
                </ProtectedLayout>
              }
            />
            <Route
              path={AppRoutes.domainOverview}
              element={
                <ProtectedLayout>
                  <DomainOverview />
                </ProtectedLayout>
              }
            />
            <Route
              path={AppRoutes.createDomain}
              element={
                <ProtectedLayout>
                  <CreateDomain />
                </ProtectedLayout>
              }
            />
            <Route path="/*" element={<PageNotFound />} />
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;
