import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useMatch, useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import CheckListIcon from '@/component-library/primitives/Icons/CheckListIcon/CheckListIcon';
import ChevronNewIcon from '@/component-library/primitives/Icons/ChevronNewIcon/ChevronNewIcon';
import FilesIcon from '@/component-library/primitives/Icons/FilesIcon/FilesIcon';
import FolderIcon from '@/component-library/primitives/Icons/FolderIcon/FolderIcon';
import GearIcon from '@/component-library/primitives/Icons/GearIcon/GearIcon';
import HelpIcon from '@/component-library/primitives/Icons/HelpIcon/HelpIcon';
import HouseIcon from '@/component-library/primitives/Icons/HouseIcon/HouseIcon';
import LayersIcon from '@/component-library/primitives/Icons/LayersIcon/LayersIcon';
import LogoIcon from '@/component-library/primitives/Icons/LogoIcon/LogoIcon';
import PaperPlaneIcon from '@/component-library/primitives/Icons/PaperPlaneIcon/PaperPlaneIcon';
import UsersIcon from '@/component-library/primitives/Icons/UsersIcon/UsersIcon';
import VolumeIcon from '@/component-library/primitives/Icons/VolumeIcon/VolumeIcon';
import WindowSidebarIcon from '@/component-library/primitives/Icons/WindowSidebarIcon/WindowSidebarIcon';
import useOutsideClick from '@/modules/shared/hooks/useOutsideClick';
import { AppRoutes } from '@/utils/routes/router';

import { selectIsCollapsableIconHidden, selectIsCollapsed, setCollapsedSidebar } from './sideMenuSlice';

interface NavLinkProps {
  to: string;
  children: React.ReactNode;
}

const NavLink = ({ to, children }: NavLinkProps) => {
  const isActive = useMatch(`${to}/*`);

  const linkStyles = isActive
    ? 'group [&>*_path]:stroke-primary-400 flex pl-2 pr-2 py-2 items-center text-primary-400 rounded bg-activeSideMenuOption'
    : 'group flex pl-2 pr-2 py-2 items-center text-gray-400 rounded hover:bg-gray-800 hover:text-white';

  return (
    <Link to={to === AppRoutes.buildMessage ? '#' : to} className={linkStyles}>
      {children}
    </Link>
  );
};

const SideMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isBuildMessageDropdownOpen, setIsBuildMessageDropdownOpen] = useState(false);
  const [showCollapsableIcon, setShowCollapsableIcon] = useState(false);
  const collapsedSidebar = useAppSelector(selectIsCollapsed);
  const isCollapsableIconHidden = useAppSelector(selectIsCollapsableIconHidden);
  const dispatch = useAppDispatch();
  const dropdownRef = useRef(null);

  useOutsideClick(dropdownRef, () => {
    setIsBuildMessageDropdownOpen(false);
  });

  const currentLocation = useLocation();
  const { pathname } = currentLocation;

  useEffect(() => {
    if (collapsedSidebar && isBuildMessageDropdownOpen) {
      setIsBuildMessageDropdownOpen(false);
    }
  }, [pathname]);

  const toggleBuildMessageDropdown = () => {
    setIsBuildMessageDropdownOpen(!isBuildMessageDropdownOpen);
  };

  const collapseSidebar = () => {
    dispatch(setCollapsedSidebar(!collapsedSidebar));
    isBuildMessageDropdownOpen && setIsBuildMessageDropdownOpen(false);
  };

  const renderNavLink = (
    to: string,
    icon: JSX.Element,
    text: string,
    classNames = 'text-sm mb-3',
    collapsableBuildMessage = false,
  ) => (
    <li className={classNames} onClick={to === AppRoutes.buildMessage ? toggleBuildMessageDropdown : undefined}>
      <NavLink to={to}>
        <div className="relative flex flex-row items-center">
          <span
            className={`${
              pathname.startsWith(to)
                ? 'group-hover:[&>*_path]:stroke-primary-400'
                : 'group-hover:[&>*_path]:stroke-white'
            } pl-[0.125rem]`}
          >
            {icon}
          </span>
          {collapsableBuildMessage ? (
            <div
              className={`flex flex-row items-center ${collapsedSidebar ? 'w-fit' : 'w-[10.625rem]'} justify-between ${
                pathname.startsWith(to)
                  ? 'group-hover:[&>*_path]:stroke-primary-400'
                  : 'group-hover:[&>*_path]:stroke-white'
              }`}
            >
              <span
                className={`ml-3 mr-3 ${
                  collapsedSidebar &&
                  `absolute hidden ${isBuildMessageDropdownOpen ? '' : 'group-hover:block'} ${
                    pathname.startsWith(to)
                      ? 'bg-custom-tooltipSideMenuOption after:border-r-custom-tooltipSideMenuOption'
                      : 'bg-gray-800 after:border-r-gray-800'
                  } p-2.5 left-[2.188rem] rounded after:content-[""] after:absolute after:top-[30%] after:right-[100%] after:border-8 after:border-y-transparent after:border-l-transparent w-max z-50`
                }`}
              >
                {text}
              </span>
              {!collapsedSidebar && (
                <ChevronNewIcon
                  color="#9bafd0"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  type={isBuildMessageDropdownOpen ? 'up' : 'down'}
                />
              )}
            </div>
          ) : (
            <>
              <span
                className={`ml-3 ${
                  collapsedSidebar &&
                  text !== `${t('SideMenu.linkSMS')}` &&
                  text !== `${t('SideMenu.linkEmail')}` &&
                  text !== `${t('SideMenu.linkPushNotification')}` &&
                  `absolute hidden group-hover:block ${
                    pathname === to
                      ? 'bg-custom-tooltipSideMenuOption after:border-r-custom-tooltipSideMenuOption'
                      : 'bg-gray-800 after:border-r-gray-800'
                  } p-2.5 left-[2.188rem] rounded after:content-[""] after:absolute after:top-[30%] after:right-[100%] after:border-8 after:border-y-transparent after:border-l-transparent w-max z-[51]`
                }`}
              >
                {text}
              </span>
            </>
          )}
        </div>
      </NavLink>
    </li>
  );

  return (
    <div
      className={`relative ${
        collapsedSidebar ? 'w-[6rem] transition-[width] duration-300' : 'w-[16rem]'
      } text-gray-500 bg-darkBlue`}
      onMouseEnter={() => !isCollapsableIconHidden && setShowCollapsableIcon(true)}
      onMouseLeave={() => !isCollapsableIconHidden && setShowCollapsableIcon(false)}
    >
      {/* Top section */}
      <div
        className={`${
          collapsedSidebar && '[&_svg]:w-[2.625rem]'
        } flex items-center ml-6 cursor-pointer xl:h-[84px] 3xl:h-24`}
        onClick={() => navigate(AppRoutes.dashboard)}
      >
        {collapsedSidebar ? <LogoIcon width="100" height="40" /> : <LogoIcon withText width="120" height="50" />}
      </div>
      <hr className={`${collapsedSidebar ? 'w-[50%]' : 'w-[83%]'} border-custom-darkGray ml-6 border-t-2 rounded-sm`} />
      {showCollapsableIcon && (
        <div className="absolute right-[-1.125rem]">
          <div
            onClick={collapseSidebar}
            className="group w-9 h-9 rounded-full bg-blackToggle hover:bg-primary-400 flex items-center justify-center cursor-pointer"
          >
            <span className="group-hover:[&>*_path]:stroke-gray-800">
              <ChevronNewIcon type={collapsedSidebar ? 'right' : 'left'} />
            </span>
          </div>
        </div>
      )}
      {/* Links */}
      <nav
        className={`mt-[1rem] pl-2 ${
          collapsedSidebar
            ? 'pr-[1.75rem]'
            : 'pr-5 overflow-y-auto [&::-webkit-scrollbar]:h-1 [&::-webkit-scrollbar]:w-1 [&::-webkit-scrollbar-track]:bg-gray-800 [&::-webkit-scrollbar-thumb]:bg-gray-600'
        } lg:h-[593.5px] 3xl:h-fit ml-5`}
      >
        <p className="pl-2 text-[0.625rem] font-medium mb-2 tracking-[0.025rem]">{t('SideMenu.mainSection')}</p>
        <ul ref={dropdownRef} className="lg:relative 3xl:static">
          {/* First portion of links */}
          {renderNavLink(AppRoutes.dashboard, <HouseIcon />, `${t('SideMenu.linkDashboard')}`)}
          {renderNavLink(
            AppRoutes.buildMessage,
            <PaperPlaneIcon />,
            `${t('SideMenu.linkBuildMessage')}`,
            `text-sm mb-3`,
            true,
            //false,
          )}

          {/* Single sends - collapsable menu */}
          {collapsedSidebar ? (
            isBuildMessageDropdownOpen && (
              <div
                className={`absolute w-[11.25rem] 3xl:top-[11.875rem] lg:top-[3rem] bg-darkBlue 3xl:left-20 lg:left-14 rounded-xl border-2 border-custom-darkGray z-50`}
                onMouseEnter={() => !isCollapsableIconHidden && setShowCollapsableIcon(false)}
                onMouseLeave={() => !isCollapsableIconHidden && setShowCollapsableIcon(true)}
              >
                {renderNavLink(
                  AppRoutes.newSMS,
                  <></>,
                  `${t('SideMenu.linkSMS')}`,
                  'text-xs mb-1 mt-3 ml-2 mr-2 [&_div>span]:ml-0',
                )}
                {renderNavLink(
                  AppRoutes.newEmail,
                  <></>,
                  `${t('SideMenu.linkEmail')}`,
                  'text-xs mb-1 ml-2 mr-2 [&_div>span]:ml-0',
                )}
                {renderNavLink(
                  AppRoutes.newPush,
                  <></>,
                  `${t('SideMenu.linkPushNotification')}`,
                  'text-xs mb-3 ml-2 mr-2 [&_div>span]:ml-0',
                )}
              </div>
            )
          ) : (
            <div
              className={`relative max-h-0 overflow-hidden ${
                isBuildMessageDropdownOpen
                  ? 'max-h-[7.5rem] transition-[max-height] duration-500'
                  : 'max-h-0 transition-[max-height] duration-500'
              } `}
            >
              <div className="absolute border-l-2 border-custom-darkGray h-[5.375rem] left-5">
                <div className="absolute w-[1.188rem] h-3 border-2 border-x-transparent border-t-transparent border-b-custom-darkGray rounded-bl-xl left-[-0.313rem] top-1"></div>
                <div className="absolute w-[1.188rem] h-3 border-2 border-x-transparent border-t-transparent border-b-custom-darkGray rounded-bl-xl left-[-0.313rem] top-10"></div>
                <div className="absolute w-[1.188rem] h-3 border-2 border-x-transparent border-t-transparent border-b-custom-darkGray rounded-bl-xl left-[-0.313rem] top-[4.875rem]"></div>
              </div>
              {renderNavLink(
                AppRoutes.newSMS,
                <></>,
                `${t('SideMenu.linkSMS')}`,
                'text-xs mb-1 ml-9 [&_div>span]:ml-0',
              )}
              {renderNavLink(
                AppRoutes.newEmail,
                <></>,
                `${t('SideMenu.linkEmail')}`,
                'text-xs mb-1 ml-9 [&_div>span]:ml-0',
              )}
              {renderNavLink(
                AppRoutes.newPush,
                <></>,
                `${t('SideMenu.linkPushNotification')}`,
                'text-xs mb-1 ml-9 [&_div>span]:ml-0',
              )}
            </div>
          )}
          {renderNavLink(AppRoutes.campaigns, <VolumeIcon />, `${t('SideMenu.linkCampaigns')}`)}
          {renderNavLink(AppRoutes.delivery, <LayersIcon />, `${t('SideMenu.linkDelivery')}`)}
          {renderNavLink(AppRoutes.templates, <FilesIcon />, `${t('SideMenu.linkTemplates')}`)}
          {renderNavLink(AppRoutes.members, <UsersIcon />, `${t('SideMenu.linkMembers')}`)}
          {renderNavLink(AppRoutes.segments, <FolderIcon />, `${t('SideMenu.linkSegments')}`)}
          {renderNavLink(AppRoutes.activityLogs, <CheckListIcon />, `${t('SideMenu.linkActivityLogs')}`)}

          {/* Second portion of links */}
          <hr className="ml-0 w-[100%] border-custom-darkGray border-t-2 rounded-sm" />
          <p
            className={`mt-5 ${
              collapsedSidebar ? 'ml-[-0.25rem] pl-0' : 'pl-2'
            } text-[0.625rem] font-medium mb-2 tracking-[0.025rem]`}
          >
            {t('SideMenu.settingsSection')}
          </p>
          {renderNavLink(
            AppRoutes.workspaces,
            <WindowSidebarIcon />,
            `${t('SideMenu.linkWorkspaces')}`,
            'text-sm mt-2',
          )}
          {renderNavLink(AppRoutes.settings, <GearIcon />, `${t('SideMenu.linkSettings')}`, 'text-sm mt-2 mb-12')}
          {renderNavLink(
            AppRoutes.help,
            <HelpIcon />,
            `${t('SideMenu.linkHelp')}`,
            `text-sm absolute lg:-bottom-[40px] 3xl:bottom-1 ${collapsedSidebar ? 'w-10' : 'w-[80%]'}`,
          )}
        </ul>
      </nav>
    </div>
  );
};

export default SideMenu;
