import React, { Fragment } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { flexRender, getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table';

import { Member } from '@/models/AudienceByWorkspace';
import { Campaign } from '@/models/Campaigns';
import { Domain } from '@/models/Domain';
import { Notification } from '@/models/NotificationsByWorkspace';
import { Segment } from '@/models/Segments';
import { AppRoutes } from '@/utils/routes/router';

import PaginationControls from '../Pagination/PaginationControls';

import { TableProps } from './types';

const Table: <T>(props: TableProps<T>) => React.ReactElement<TableProps<T>> = ({
  columns,
  data,
  caption,
  className,
  headerStyle,
  showPagination = true,
  showRecordsPerPage = true,
  fullWidth = true,
}) => {
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 10,
      },
    },
  });
  const navigate = useNavigate();
  const isValueAMember = (value: any): value is Member => !!value?.attributes;
  const isValueASegment = (value: any): value is Segment => value?.entityType === 'Segment';
  const isValueADelivery = (value: any): value is Segment => value?.entityType === 'Scheduled-Notifications';
  const isValueADomain = (value: any): value is Domain => value?.entityType === 'Domain';
  const isValueACampaign = (value: any): value is Campaign => value?.entityType === 'Campaign';

  const currentPageIndex = table.getState().pagination.pageIndex;
  const rows = table.getPaginationRowModel().rows;
  const lastTableRow = rows[rows.length - 1];
  const firstCell = lastTableRow.getVisibleCells()[0];
  const lastCell = lastTableRow.getVisibleCells()[lastTableRow.getVisibleCells().length - 1];

  return (
    <>
      <table
        data-cy="table"
        className={`table-auto ${fullWidth && 'w-full'} border-collapse bg-darkBlue rounded-lg ${className?.table}`}
      >
        <caption className="text-left">{caption}</caption>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={`h-[4.5rem] border-b-2 border-gray-700 px-7 py-2 text-left text-sm text-secondary-400 font-semibold ${className?.header}`}
                  style={headerStyle}
                >
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row, i) => (
            <Fragment key={i}>
              <tr
                key={row.id}
                className={`group hover:cursor-pointer text-sm border-t-[0.063rem] border-b-[0.063rem] border-gray-800 last:border-b-0 first:border-t-0 hover:bg-tableRowHoverBG ${className?.row}`}
                data-testid="table-row"
                onClick={() => {
                  if (isValueAMember(row.original)) {
                    return navigate(generatePath(AppRoutes.memberProfile, { id: (row.original as Member).id }));
                  } else if (isValueASegment(row.original)) {
                    return navigate(generatePath(AppRoutes.segmentOverview, { id: (row.original as Segment).id }));
                  } else if (isValueADomain(row.original)) {
                    return navigate(
                      generatePath(AppRoutes.domainOverview, { domainName: (row.original as Domain).domainName }),
                    );
                  } else if (isValueADelivery(row.original)) {
                    return navigate(
                      generatePath(AppRoutes.deliveryOverview, { id: (row.original as unknown as Notification).id }),
                    );
                  } else if (isValueACampaign(row.original)) {
                    return navigate(
                      generatePath(AppRoutes.campaignOverview, { id: (row.original as unknown as Campaign).id }),
                    );
                  }
                }}
              >
                {row.getVisibleCells().map((cell, i) => (
                  <td
                    key={cell.id}
                    className={`px-7 group-hover:bg-tableRowHoverBG group-hover:rounded-none ${
                      cell.id === firstCell.id ? 'rounded-l-lg group-hover:rounded-bl-lg' : ''
                    } ${
                      cell.id === lastCell.id ? 'rounded-r-lg group-hover:rounded-br-lg' : ''
                    } text-gray-300 bg-darkBlue first:max-w-[10rem] max-w-xs ${className?.cell ?? ''}`}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            </Fragment>
          ))}
        </tbody>
      </table>
      {showPagination && (
        <PaginationControls
          table={table}
          currentPageIndex={currentPageIndex}
          data={data}
          showRecordsPerPage={showRecordsPerPage}
          className={className?.pagination}
        />
      )}
    </>
  );
};

export default Table;
