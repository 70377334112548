/* eslint-disable @typescript-eslint/ban-ts-comment */

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import ChevronIcon from '@/component-library/primitives/Icons/ChevronIcon/ChevronIcon';
import Stepper from '@/component-library/primitives/Stepper/Stepper';
import { AppRoutes } from '@/utils/routes/router';

const CampaignOverview = () => {
  const { t } = useTranslation();

  const { id: campaignId } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();

  const steps = [
    t('CampaignOverviewPage.infoStep'),
    t('CampaignOverviewPage.triggersStep'),
    t('CampaignOverviewPage.settingsStep'),
    t('CampaignOverviewPage.goalAndExitStep'),
    t('CampaignOverviewPage.workflowStep'),
  ];
  const onBack = () => {
    activeStep === 0 ? navigate(AppRoutes.campaigns) : setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  return (
    <>
      <div className="flex flex-col items-center mt-12">
        <div>
          <div className="position: relative mb-8">
            <div className="position: absolute top-0.5 -left-10 cursor-pointer" onClick={onBack}>
              <ChevronIcon type={'left'} width={'24'} height={'24'} viewBox={'0 0 24 24'} />
            </div>
            <h1 className="text-custom-aliceBlue font-semibold text-xl mb-3">{t('CampaignOverviewPage.title')}</h1>
            <span className="text-custom-aliceBlue">{t('CampaignOverviewPage.subtitle')}</span>
          </div>
          <div className="flex justify-center">
            <Stepper steps={steps} activeStep={activeStep} />
          </div>
        </div>
      </div>
    </>
  );
};

export default CampaignOverview;
